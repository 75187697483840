// Imports the necessary functions from Vue and Pinia
import './assets/main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Vant from 'vant'
import 'vant/lib/index.css'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { Edit } from '@element-plus/icons-vue' 

createApp(App)
  .use(createPinia())
  .use(router) // 使用路由插件
  .use(Vant) // 使用 Vant 组件库
  .use(ElementPlus) // 使用 ElementPlus组件库
  .component(Edit.name, Edit) 
  .mount('#app'); // 将 Vue 应用实例挂载到元素上
