<template>
  <div class="header">
    <div class="logo"> 
      <img src="@/assets/logo.png"/>
      怡养健康人力资源管理系统 部门科室端
    </div>
    <div class="user-info">
      <!-- User Info -->
      <el-dropdown @command="changeDepartment">
        <span class="el-dropdown-link">
          切换科室
        </span>
        <template v-slot:dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="department in departments" :key="department.id" :command="department.id">
              {{ department.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-button type="danger" @click="logOut">注销</el-button>

    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, onMounted} from 'vue';
import useUserStore from '@/stores/user';
import api from '@/api';
export default {
  setup() {
    const router = useRouter();
    const userStore = useUserStore(); 
    const departments = ref([]);
    const directorId = ref(userStore.director_id);

    onMounted(() => {
      getDirectorDepartment();
    });
    const getDirectorDepartment = async () => {
      try {
        const res = await api.get('/director/department', {
          params: {
            director_id: userStore.director_id
          }
        });
        console.log(res.data.data); // 处理响应数据
        departments.value = res.data.data.departments;
      } catch (error) {
        // 处理错误
        console.error('Error fetching data:', error);
      }
    };

    const logOut = function () {
      userStore.loginFlag = 'false';
      localStorage.setItem('loginFlag', 'false');
      router.push({
        name: 'login',
      });
    };

    const changeDepartment = function (newDepartmentId) {
      userStore.updateDepartmentId(newDepartmentId);
      // 跳转到首页并刷新页面
      router.push({
        name: 'home',
      });
      // 如果你需要刷新页面，可以添加以下代码
      router.go(0);
    }; 

    return {
      userName: userStore.userName,
      logOut,
      directorId,
      changeDepartment,
      departments
    };
  },
};
</script>

<style>

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #006eff;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 21px;
    height: 55px;
    color: #ffffff
  }
  .logo {
    font-size:19px;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .logo img {
    height: 40px;
  }

  .user-info {
    font-size: 16px;
  }
/* 全局样式或者组件的 <style> 标签中 */
.el-dropdown-link {
  cursor: pointer;
  color: #ffffff; /* 自定义链接颜色 */
  display: flex;
  border:1px solid #eaeaea;
  border-radius: 5px;
  padding: 5px 10px;
  align-items: center;
  margin-right:10px;
  margin-top:3px;
  color: #ffffff
}
.el-dropdown-link:hover {
  color: #ffffff; /* 自定义鼠标悬停时的文字颜色 */
  border:1px solid #aec7e7;
}

.el-icon-arrow-down.el-icon--right {
  margin-left: 5px; /* 自定义图标与文字之间的间距 */
}

/* 如果你需要自定义下拉菜单的样式，可以这样做 */
.el-dropdown-menu {
  /* 自定义下拉菜单的样式 */
}

.el-dropdown-menu__item {
  /* 自定义下拉菜单项的样式 */
}

/* 鼠标悬停时的高亮样式 */
.el-dropdown-menu__item:hover {
  background-color: #ecf5ff; /* 自定义鼠标悬停时的背景颜色 */
  color: #66b1ff; /* 自定义鼠标悬停时的文字颜色 */
}

</style>
