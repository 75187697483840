// Import Vue composition API functions
import { ref } from 'vue';
// Import defineStore from Pinia
import { defineStore } from 'pinia';

// Define the useUserStore function
const useUserStore = defineStore('user', () => {
  // Login flag
  const loginFlag = ref(localStorage.getItem('loginFlag'));
  // User ID
  const director_id = ref(localStorage.getItem('director_id'));
  // Department ID
  const department_id = ref(localStorage.getItem('department_id'));

  // Function to update user and department IDs
  function updateUserIdAndDepartmentId(newId, newDepartmentId) {
    localStorage.setItem('director_id', newId);
    localStorage.setItem('department_id', newDepartmentId);
  }

    // Function to update the department ID
    function updateDepartmentId(newDepartmentId) {
      localStorage.setItem('department_id', newDepartmentId);
      department_id.value = newDepartmentId;
    }
  
  return {
    loginFlag,
    director_id,
    department_id,
    updateDepartmentId,
    updateUserIdAndDepartmentId
  }
});

export default useUserStore;
